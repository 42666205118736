import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Col, Input } from 'reactstrap'
import { EmploymentType, SurveyData } from '../../shared/FHBData'
export type EmployeeDetailProps = {
    onUpdate: (data: SurveyData) => any,
    surveyData: SurveyData
}
export function EmployeeDetail(props: EmployeeDetailProps) {

    const [formData, setFormData] = useState(props.surveyData)

    useEffect(
        () => {
            props.onUpdate(formData)
        }, [formData, props]
    )
    return (
        <>
            <h4>Employment Detail</h4>
            <FormGroup className="row">
                <Label md={12} >Your employment type * </Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType1" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData | null = formData ? formData : null
                                    let empDtl: EmploymentType = formData.employmentDetail!
                                    empDtl.employmentType = 'Full time'
                                    setFormData(frmData!)
                                }
                            } />
                            Full time <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType2" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData | null = formData ? formData : null
                                    let empDtl: EmploymentType = formData.employmentDetail!
                                    empDtl.employmentType = 'Part time'
                                    setFormData(frmData!)
                                }
                            } />
                            Part time <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType3" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData | null = formData ? formData : null
                                    let empDtl: EmploymentType = formData.employmentDetail!
                                    empDtl.employmentType = 'Casual'
                                    setFormData(frmData!)
                                }
                            } />
                            Casual <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType4" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData | null = formData ? formData : null
                                    let empDtl: EmploymentType = formData.employmentDetail!
                                    empDtl.employmentType = 'Self employed'
                                    setFormData(frmData!)
                                }
                            } />
                            Self employed <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label md={12} >Length of Employment * </Label>
                <Col md={12}>
                    <Label>
                        <Input placeholder="Year" type="number" onChange={(e) => {
                            let val = e.target.value
                            let frmData = formData
                            formData.employmentDetail!.lengthOfEmployment.year = val as any
                            setFormData(frmData)
                        }} />
                    </Label>
                    <Label>
                        <Input placeholder="Month" type="number" onChange={(e) => {
                            let val = e.target.value
                            let frmData = formData
                            formData.employmentDetail!.lengthOfEmployment.month = val as any
                            setFormData(frmData)
                        }} />
                    </Label>

                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label md={12} >Your income category * </Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory0" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.incomeCategory = "$0-24k"
                                }
                            } />
                            $0-24k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory1" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData = formData
                                    formData.employmentDetail!.incomeCategory = '$25-49k'
                                    setFormData(frmData)
                                }
                            } />
                            $25-49k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory2" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData = formData
                                    formData.employmentDetail!.incomeCategory = '$50-79k'
                                    setFormData(frmData)
                                }
                            } />
                            $50-79k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory3" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData = formData
                                    formData.employmentDetail!.incomeCategory = '$80-99k'
                                    setFormData(frmData)
                                }
                            } />
                            $80-99k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory4" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData = formData
                                    formData.employmentDetail!.incomeCategory = '$100k+'
                                    setFormData(frmData)
                                }
                            } />
                            $100k+ <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
        </>
    )
}
