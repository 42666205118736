export type EmploymentType = {
    employmentType: 'Full time' | 'Part time' | 'Casual' | 'Self employed' | '',
    lengthOfEmployment: {
        year: number,
        month: number
    },
    incomeCategory:  '$0-24k' | '$25-49k' | '$50-79k' | '$80-99k' | '$100k+' | ''
} 


export type SurveyData = {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    email: string,
    formId: string,
    postCode: string,
    employmentDetail?: EmploymentType,
    maritalStatus?: 'Married/DeFacto' | 'Single' | '',
    parnerEmploymentDetail?: {
        name: string,
        employmentType: 'Full time' | 'Part time' | 'Casual' | 'Self employed' | '',
        lengthOfEmployment: {
            year: number,
            month: number
        },
        incomeCategory:     '$0-24k' | '$25-49k' | '$50-79k' | '$80-99k' | '$100k+' | ''
    },
    numberOfChildren?: number,
    qualifyFirstHomeOwners?: 'Yes' | 'No' | 'Unsure' | '',
    haveOwnedhome?: 'Yes' | 'No' | '',
    australianCitizen?: 'Yes' | 'No' | '',
    longLivedCurrentHome?: {
        year: number,
        month: number
    },
    rentCurrentHome?: number,
    haveDeposit?: string
}

export const masterBGColor = '#308da4'

export const scoreIncome = {
    '$0-24k': 0, '$25-49k': 1, '$50-79k': 2, '$80-99k': 3, '$100k+': 4, "": 0
}

//stuart.p@hcorp.com.au, shane@hcorp.com.au , KamSidhu1963@hotmail.com --- karen.f@hcorp.com.au - rose.w@hcorp.com.au

export const postCodeAndSales: {[email: string] : any[]} = {
    "arka.suryawan@gmail.com": [4300,4301,4303,4304,4305,4306, 4109,4112,4113,4114,4115,4116,4117,4118,4119,4123,4127,4128,4131,4132,4133,4205],
    "arka.suryawan@outlook.co.id" : [4207,4208,4209], 
    // "karen.f@hcorp.com.au" : [4109,4112,4113,4114,4115,4116,4117,4118,4119,4123,4127,4128,4131,4132,4133,4205]
}

 
export const initialData: SurveyData = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    formId: '',
    postCode: '',
    email: '',
    employmentDetail: {
        employmentType: '',
        lengthOfEmployment: {
            year: 0,
            month: 0
        },
        incomeCategory: ''
    },
    maritalStatus: '',
    parnerEmploymentDetail: {
        name: '',
        employmentType: '',
        lengthOfEmployment: {
            year: 0,
            month: 0
        },
        incomeCategory: ''
    },
    numberOfChildren: 0,
    qualifyFirstHomeOwners: '',
    haveOwnedhome: '',
    australianCitizen: '',
    longLivedCurrentHome: {
        year: 0,
        month: 0
    },
    rentCurrentHome: 0,
    haveDeposit: '0'
}

export const  PosData: any = (surveyData: SurveyData, surveyDate: string, surveyTime: string, status: string ) => {
    return {
        "firstName": surveyData.firstName,
        "lastName": surveyData.lastName,
        "phoneNumber": surveyData.phoneNumber,
        "formId": surveyData.formId,
        "email": surveyData.email,
        "postCode": surveyData.postCode,
        "employmentDetail_employmentType": surveyData.employmentDetail!.employmentType,
        "employmentDetail_lengthOfEmployment_year": surveyData.employmentDetail!.lengthOfEmployment.year,
        "employmentDetail_lengthOfEmployment_month": surveyData.employmentDetail!.lengthOfEmployment.month,
        "employmentDetail_incomeCategory": surveyData.employmentDetail!.incomeCategory,
        "maritalStatus": surveyData.maritalStatus,
        "parnerEmploymentDetail_name": surveyData.parnerEmploymentDetail!.name,
        "parnerEmploymentDetail_employmentType": surveyData.parnerEmploymentDetail!.employmentType,
        "parnerEmploymentDetail_incomeCategory": surveyData.parnerEmploymentDetail!.incomeCategory,
        "parnerEmploymentDetail_lengthOfEmployment_year": surveyData.parnerEmploymentDetail!.lengthOfEmployment.year,
        "parnerEmploymentDetail_lengthOfEmployment_month": surveyData.parnerEmploymentDetail!.lengthOfEmployment.month,
        "numberOfChildren": surveyData.numberOfChildren,
        "qualifyFirstHomeOwners": surveyData.qualifyFirstHomeOwners,
        "haveOwnedhome": surveyData.haveOwnedhome,
        "australianCitizen": surveyData.australianCitizen,
        "longLivedCurrentHome_year": surveyData.longLivedCurrentHome!.year,
        "longLivedCurrentHome_month": surveyData.longLivedCurrentHome!.month,
        "rentCurrentHome": surveyData.rentCurrentHome,
        "haveDeposit": surveyData.haveDeposit,
        "surveyDate": surveyDate ? surveyDate : '',
        "surveyTime": surveyTime  ? surveyTime : '',
        "status": status,
    } 
}