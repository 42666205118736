import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Col, Input } from 'reactstrap'
import { SurveyData } from '../../shared/FHBData'
export type CurrentHomeProps = {
    onUpdate: (data: SurveyData) => any,
    surveyData: SurveyData
}
export function CurrentHome(props: CurrentHomeProps) {
    const [formData, setFormData] = useState(props.surveyData)
     
    useEffect(
        () => {
            props.onUpdate(formData)
        }, [formData, props]
    ) 
    return (
        <>
            <FormGroup className="row">
                <Label md={12} htmlFor="NumberOfChildren" >Number of children / dependants living with you </Label>
                <Col sm="4">
                    <input
                        className="form-control"
                        id="NumberOfChildren"
                        type="number" 
                        onChange={
                            (e) => {
                                let frmData: SurveyData = formData 
                                frmData.numberOfChildren = e.target.value  as any
                            }
                        }
                    />
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label md={12} >Do you qualify for the $15k QLD First Home Owners Grant?</Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="DoYouQualify1" name="qualifyFirstHomeOwners" type="radio"  onClick={
                                () => {
                                    let frmDat = formData  
                                    frmDat.qualifyFirstHomeOwners = 'Yes'
                                    setFormData(frmDat)
                                }
                            }/>
                            Yes <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="DoYouQualify2" name="qualifyFirstHomeOwners" type="radio"  onClick={
                                () => {
                                    let frmDat = formData  
                                    frmDat.qualifyFirstHomeOwners = 'No'
                                    setFormData(frmDat)
                                }
                            }/>
                            No <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="DoYouQualify3" name="qualifyFirstHomeOwners" type="radio"  onClick={
                                () => {
                                    let frmDat = formData  
                                    frmDat.qualifyFirstHomeOwners = 'Unsure'
                                    setFormData(frmDat)
                                }
                            }/>
                            Unsure <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label md={12} >Have you ever Owned a home in Australia?</Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option2" id="DoYouQualify1" name="haveOwnedhome" type="radio"  onClick={
                                () => {
                                    let frmDat = formData  
                                    frmDat.haveOwnedhome = 'Yes'
                                    setFormData(frmDat)
                                }
                            }/>
                            Yes <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="DoYouQualify2" name="haveOwnedhome" type="radio"  onClick={
                                () => {
                                    let frmDat = formData  
                                    frmDat.haveOwnedhome = 'No'
                                    setFormData(frmDat)
                                }
                            }/>
                            No <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label md={12} >Are either you or your partner and Australian Citizen or a Permanent Resident?</Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="DoYouQualify1" name="australianCitizen" type="radio"  onClick={
                                () => {
                                    let frmDat = formData  
                                    frmDat.australianCitizen = 'Yes'
                                    setFormData(frmDat)
                                }
                            }/>
                            Yes <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="DoYouQualify2" name="australianCitizen" type="radio"  onClick={
                                () => {
                                    let frmDat = formData  
                                    frmDat.australianCitizen = 'No'
                                    setFormData(frmDat)
                                }
                            }/>
                            No <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row"  >
                <Label md={12} >How Long have you lived in your current home?</Label>
                <Col md={12}>
                    <Label>
                        <Input placeholder="Year" type="number"  onChange={(e)=> {
                            let val = e.target.value
                            let frmData = formData  
                            formData.longLivedCurrentHome!.year = val as any
                            setFormData(frmData)
                        }} />
                    </Label>
                    <Label>
                        <Input placeholder="Month" type="number"  onChange={(e)=> {
                            let val = e.target.value
                            let frmData = formData  
                            formData.longLivedCurrentHome!.month = val as any
                            setFormData(frmData)
                        }} />
                    </Label>

                </Col>
            </FormGroup>

            <FormGroup className="row">
                <Label md={12} htmlFor="rent" >How much do you pay per week in rent </Label>
                <Col sm="4">
                    <input
                        className="form-control"
                        id="rent"
                        type="text"
                        onChange={(e)=> {
                            let val = e.target.value
                            let frmData = formData  
                            formData.rentCurrentHome = val as any
                            setFormData(frmData)
                        }} 
                    />
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label md={12} htmlFor="deposit" >How much home deposit have you saved? </Label>
                <Col sm="4">
                    <input
                        className="form-control"
                        id="deposit"
                        type="text"
                        onChange={(e)=> {
                            let val = e.target.value
                            let frmData = formData  
                            formData.haveDeposit = val as any
                            setFormData(frmData)
                        }} 
                    />
                </Col>
            </FormGroup>
        </>
    )
}