import React, { useState, useEffect } from 'react'
import { Button, Alert, FormGroup, Label, Col } from 'reactstrap'
import { EmployeeDetail } from './survey/EmployeeDetail'
import { HaveParner } from './survey/HavePartner'
import { CurrentHome } from './survey/CurrenHome'
import Moment from 'moment'
import Datetime from 'react-datetime';
import Select from "react-select";
import { SurveyData, masterBGColor, PosData, initialData } from '../shared/FHBData'
import { MainContext } from '../App'
// import * as firebase from 'firebase';
const scoreIncome = {
    '$0-24k': 0, '$25-49k': 1, '$50-79k': 2, '$80-99k': 3, '$100k+': 4, "": 0
}
// const firebaseDB = firebase.database() 
export interface mailData {
    fromEmail: string;
    fromName: string;
    to: string;
    cc: string;
    subject: string;
    mailContent: string;
    replayTo: string
}

export type HomePage2Props = {
    firebaseApp: firebase.app.App;
}


export function HomePage2(props: HomePage2Props) {

    let dataUser: SurveyData = initialData
    const firebaseApp = props.firebaseApp
    const [version, setVersion] = useState(0)
    // const [surveyfirstName, setSurveryFirstName] = useState("")
    const [surveyData, setSurveyData] = useState(dataUser)
    const [section, setSection] = useState(1)
    const [haveError, setHaveError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [surveyDate, setSurveyDate] = useState()
    const [surveyTime, setSurveyTime] = useState()
    //let version = 0
    useEffect(() => {
        console.log("Masuk Sini")
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('ld')) {
            const platform = urlParams.get('ld')
            const fid = urlParams.get('id')
            firebaseApp.database().ref(`${platform!}/${fid}`).on("value", async snapshot => {
                const dataLeads = snapshot.val()
                let data: SurveyData = surveyData;
                if (dataLeads) {
                    if (platform === 'facebookLeads') {
                        data.firstName = dataLeads['First Name'];
                        data.lastName = dataLeads['Last name'];
                        data.formId = dataLeads['Form ID'];
                        data.postCode = dataLeads['Post Code'];
                        data.phoneNumber = dataLeads['Phone Number'];
                        data.email = dataLeads['Email'];
                    } else if (platform === 'websiteLeads') {
                        data.firstName = dataLeads['firstName'];
                        data.lastName = dataLeads['lastName'];
                        data.formId = dataLeads[fid!];
                        data.postCode = dataLeads['Post Code'];
                        data.phoneNumber = dataLeads['Mobile'];
                        data.email = dataLeads['Email'];
                    }
                    setSurveyData(data)
                    //console.log("surveyData Baru", surveyData)
                    // version++
                    setVersion(1)
                } else {
                    window.location.href = 'https://firsthomeboost.com.au/sms-broken-link/'
                }

            })
        } else {
            let data: SurveyData = surveyData;
            data.firstName = urlParams.get('firstName')!;
            data.lastName = urlParams.get('lastName')!;
            data.formId = urlParams.get('formId')!;
            data.postCode = urlParams.get('postCode')!;
            data.phoneNumber = urlParams.get('phoneNumber')!;
            setSurveyData(data)
            // version++
            console.log("surveyData Baru", surveyData)
            setVersion(1)
        }
    }, [surveyData, version, firebaseApp])
    //console.log(" version++",  version++ )
    // console.log("dataUser",  JSON.stringify(surveyData))
    if (version === 0) {
        return <span />
    }
    // console.log("dataUser", JSON.stringify(surveyData))
    const employeeDetail = (typ: SurveyData) => {
        setSurveyData(typ)
    }
    window.scrollTo(0, 0);

    return (
        <MainContext.Consumer>
            {
                c => {
                    if (!c) {
                        return (<h1>Tidak ada contenct</h1>)
                    }

                    let nextBtnTxt = <> Continue <i className="now-ui-icons arrows-1_minimal-right" /> </>
                    if (section === 1) {
                        nextBtnTxt = <span>Start The Survey</span>
                    }

                    let isiSection = <div>
                        <p >Dear {surveyData.firstName} {surveyData.lastName}, </p>
                        <p >
                            Congratulations on taking the first step to stop renting and owning your own home. <br />
                            These are exciting times and we are here to help every step of the way!
                                        </p>
                        <p >Now to help you further please fill the form to take the 2-minute survey to see if your qualify for $40,000 in available grants</p>
                        <p >Don’t wait as this offer simply can’t last.</p>

                        <br />

                    </div>



                    let btnNext = <Button style={{ float: 'right' }} className="btn btn-info btn-round" onClick={() => {
                        if (section === 1) {
                            setSection(section + 1)
                        } else if (section === 2) {
                            if (surveyData.employmentDetail!.employmentType !== '' && surveyData.employmentDetail!.incomeCategory !== '') {
                                setSection(section + 1)
                                setHaveError(false)
                            } else {
                                setErrorMessage("Please complate the question")
                                setHaveError(true)

                            }
                        } else if (section === 3) {
                            if (surveyData.maritalStatus === 'Single') {
                                setSection(section + 1)
                                setHaveError(false)
                            } else {

                                if (surveyData.parnerEmploymentDetail!.name !== '' && surveyData.parnerEmploymentDetail!.employmentType !== '' && surveyData.parnerEmploymentDetail!.incomeCategory !== '') {
                                    setSection(section + 1)
                                    setHaveError(false)
                                } else {
                                    setErrorMessage("Please complate the question")
                                    setHaveError(true)

                                }

                            }
                        } else if (section === 4) {
                            console.log("Masuk Sini", surveyData.qualifyFirstHomeOwners !== '' &&
                                surveyData.australianCitizen !== '' &&
                                surveyData.haveOwnedhome !== '', surveyData.qualifyFirstHomeOwners, surveyData.australianCitizen, surveyData.haveOwnedhome, surveyData)
                            if (
                                surveyData.qualifyFirstHomeOwners !== '' &&
                                surveyData.australianCitizen !== '' &&
                                surveyData.haveOwnedhome !== ''
                            ) {
                                setSection(section + 1)
                                setHaveError(false)
                            } else {
                                setErrorMessage("Please complate the question")
                                setHaveError(true)

                            }
                        }

                    }}>{nextBtnTxt}</Button>
                    let btnPrev = <Button style={{ float: 'left' }} className="btn btn-info btn-round" onClick={() => setSection(section - 1)}><i className="now-ui-icons arrows-1_minimal-left" /> Back </Button>
                    let btnSubmit = <span />

                    if (section === 1) {
                        btnPrev = <span />
                    }
                    if (section === 5) {

                        let income1 = scoreIncome[surveyData.employmentDetail!.incomeCategory] ? scoreIncome[surveyData.employmentDetail!.incomeCategory] : 0
                        let income2 = scoreIncome[surveyData.parnerEmploymentDetail!.incomeCategory] ? scoreIncome[surveyData.parnerEmploymentDetail!.incomeCategory] : 0
                        let incomeScore = income1 + income2

                        let scoreEmployee1 = (surveyData.employmentDetail!.lengthOfEmployment.year ? surveyData.employmentDetail!.lengthOfEmployment.year : 0 * 12) + (surveyData.employmentDetail!.lengthOfEmployment.month ? surveyData.employmentDetail!.lengthOfEmployment.month : 0)
                        let scoreEmployee2 = (surveyData.parnerEmploymentDetail!.lengthOfEmployment.year ? surveyData.parnerEmploymentDetail!.lengthOfEmployment.year : 0 * 12) + (surveyData.parnerEmploymentDetail!.lengthOfEmployment.month ? surveyData.parnerEmploymentDetail!.lengthOfEmployment.month : 0)
                        let qualifiedEmployee1 = 0
                        if (
                            (surveyData.employmentDetail!.employmentType === 'Full time' && scoreEmployee1 > 2) ||
                            (surveyData.employmentDetail!.employmentType === 'Part time' && scoreEmployee1 > 5) ||
                            (surveyData.employmentDetail!.employmentType === 'Casual' && scoreEmployee1 > 11) ||
                            (surveyData.employmentDetail!.employmentType === 'Self employed' && scoreEmployee1 > 11)
                        ) {
                            qualifiedEmployee1 = 1
                        }
                        let qualifiedEmployee2 = 0
                        if (
                            (surveyData.parnerEmploymentDetail!.employmentType === 'Full time' && scoreEmployee2 > 2) ||
                            (surveyData.parnerEmploymentDetail!.employmentType === 'Part time' && scoreEmployee2 > 5) ||
                            (surveyData.parnerEmploymentDetail!.employmentType === 'Casual' && scoreEmployee2 > 11) ||
                            (surveyData.parnerEmploymentDetail!.employmentType === 'Self employed' && scoreEmployee2 > 11)
                        ) {
                            qualifiedEmployee2 = 1
                        }
                        let qualifiedEmployee = qualifiedEmployee1 + qualifiedEmployee2

                        if (incomeScore > 2) {
                            if (qualifiedEmployee > 0) {

                                btnSubmit = <Button className="btn btn-info btn-round" onClick={() => {

                                    let posData = PosData(surveyData, '', '', "[FHB – Qualified]  FAIL DUE TO Insufficent length of time in employment")
                                    let deposit = surveyData.haveDeposit ? surveyData.haveDeposit.replace(".", "").replace(" ", "").replace("$", "").replace(",", "") : '0'
                                    let depositNumber = deposit as any / 1
                                    if (depositNumber > 10000) {
                                        posData = PosData(surveyData, '', '', "[FHB – Qualified] MORE INFO REQ - FAIL DUE TO Insufficent length of time in employment")
                                    }
                                    var newPostKey = firebaseApp.database().ref().child('qualification').push().key;
                                    firebaseApp.database().ref('qualification/' + newPostKey).set(posData).then(
                                        d => {
                                            setSection(6)
                                        }
                                    ).catch(
                                        e => {
                                            console.log("Masuk Error", e)
                                        }
                                    )

                                }}> Submit </Button>
                                isiSection = <>
                                    <p>CONGRATULATIONS YOU HAVE QUALIFED TO RECEIVE AN ADDITIONAL $10,000 BUILDER VOUCHER!!! SO TOGETHER WITH DOUBLING THE QLD FIRST HOME OWNERS GRANT AND WAIVING OF STAMP DUTY THATS UP TO $50,000 WORTH OF HELP TO GET YOU INTO YOUR FIRST HOME.</p>
                                    <p>To find out how this special offer can work for you, book your preferred time to have one of our consultants give you a call to discuss your options? <strong>NO COST AND NO OBLIGATION.</strong></p>
                                    <FormGroup className="row">
                                        <Label md={12} >Preferred Day for a quick call this week</Label>
                                        <Col md={12}>
                                            <Datetime timeFormat={false} closeOnSelect onChange={(val: any) => setSurveyDate(val)} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Col md={12}>
                                            <Select
                                                className="react-select react-select-primary"
                                                classNamePrefix="react-select"
                                                name="singleSelect"
                                                onChange={(value: any) => {
                                                    setSurveyTime(value.value)
                                                }}
                                                options={[
                                                    { value: "Morning ", label: "Morning" },
                                                    { value: "Afternoon", label: "Afternoon" },
                                                    { value: "Evening", label: "Evening" }
                                                ]}
                                                placeholder="Preferred Time"
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                            } else {

                                let posData = PosData(surveyData, '', '', "[FHB – Qualified] FAIL DUE TO Insufficent length of time in employment")
                                let deposit = surveyData.haveDeposit ? surveyData.haveDeposit.replace(".", "").replace(" ", "").replace("$", "").replace(",", "") : '0'
                                isiSection = <p>GOOD NEWS YOU DO QUALIFY FOR OUR SPECIAL HOME BUYERS OFFER, HOWEVER YOU JUST NEED A LITTLE LONGER IN YOUR CURRENT JOB(S) WE WILL TOUCH BASE WITH YOU AS the time is right talk soon regards the team at First Home Boost!</p>
                                let depositNumber = deposit as any / 1
                                if (depositNumber > 10000) {
                                    posData = PosData(surveyData, '', '', "[FHB – Qualified] MORE INFO REQ - FAIL DUE TO Insufficent length of time in employment")
                                    isiSection = <p>Thanks for providing us with your information. At this stage we need further information from you to determine if you qualify. The team at First Home Boost will be in contact with you in the next 24 to 48 hours to discuss further. We look forward to speaking with you then.</p>
                                }
                                var newPostKey = firebaseApp.database().ref().child('qualification').push().key;
                                firebaseApp.database().ref('qualification/' + newPostKey).set(posData).then(
                                    d => {
                                        console.log("Masuk Sukses", d)
                                    }
                                ).catch(
                                    e => {
                                        console.log("Masuk Error", e)
                                    }
                                )
                                
                            }
                        } else {
                            // const posData = PosData(surveyData, '', '', "[First Home Boost QUALIFY] FAIL DUE TO INCOME")
                            let posData = PosData(surveyData, '', '', "[FHB – Qualified] FAIL DUE TO INCOME")
                            isiSection = <p>UNFORTUNATELY YOU DO NOT QUALIFY FOR OUR SPECIAL FIRST HOME BUYERS OFFER AT THIS STAGE WE WILL TOUCH BASE WITH YOU IN 6 to 12 months time to see if your circumstances have changed. regards the team at First Home Boost!</p>
                            let deposit = surveyData.haveDeposit ? surveyData.haveDeposit.replace(".", "").replace(" ", "").replace("$", "").replace(",", "") : '0'
                            let depositNumber = deposit as any / 1
                            if (depositNumber > 10000) {
                                posData = PosData(surveyData, '', '', "[FHB – Qualified] MORE INFO REQ - FAIL DUE TO INCOME")
                                isiSection = <p>Thanks for providing us with your information. At this stage we need further information from you to determine if you qualify. The team at First Home Boost will be in contact with you in the next 24 to 48 hours to discuss further. We look forward to speaking with you then.</p>
                            }
                            let newPostKey = firebaseApp.database().ref().child('qualification').push().key;
                            firebaseApp.database().ref('qualification/' + newPostKey).set(posData).then(
                                d => {
                                    console.log("Masuk Sukses", d)
                                }
                            ).catch(
                                e => {
                                    console.log("Masuk Error", e)
                                }
                            )
                            
                        }


                        btnNext = <span />
                        btnPrev = <span />
                    }


                    if (section === 2) {
                        isiSection = <EmployeeDetail onUpdate={employeeDetail} surveyData={surveyData} />
                    } else if (section === 3) {
                        isiSection = <HaveParner onUpdate={employeeDetail} surveyData={surveyData} />
                    } else if (section === 4) {
                        isiSection = <CurrentHome onUpdate={employeeDetail} surveyData={surveyData} />
                    } else if (section === 6) {
                        btnNext = <span />
                        btnPrev = <span />
                        isiSection = <>
                            Thank you for your appointment request. Our service team will confirm your date and time for availability, and we will send you a confirmation SMS.
                        </>
                    }

                    // console.log("surveyData", surveyData)
                    return (
                        <>
                            <style>
                                {
                                    `
                                    .form-check-radio input[type="radio"] + .form-check-sign:after, .form-check-radio input[type="radio"] {
                                        width: 0
                                    }
                                    .form-check-inline {
                                        margin-right: 1rem
                                    }
                                    `
                                }
                            </style>
                            <div style={{ background: masterBGColor, padding: '15px 15px 2px 15px', zIndex: 999, textAlign: 'center', position: 'fixed', top: 0, width: '100%' }}>
                                <img src="/assets/img/FHB-White-Keys.png" alt="" />
                            </div>
                            <Alert color="warning" isOpen={haveError} style={{ marginTop: 75 }}>
                                <div className="container">
                                    <div className="alert-icon">
                                        <i className="now-ui-icons travel_info"></i>
                                    </div>
                                    <strong>Not COMPLETE!</strong> {errorMessage}
                                    <button
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={() => setHaveError(false)}
                                    >
                                        <span aria-hidden="true">
                                            <i className="now-ui-icons ui-1_simple-remove"></i>
                                        </span>
                                    </button>
                                </div>
                            </Alert>
                            <div style={{ maxWidth: 420, margin: haveError ? '0 auto 55px auto' : '75px auto 55px auto', display: 'table', padding: '0px 15px', height: 'calc(100vh - 125px)', overflow: 'scroll' }}>
                                {isiSection}
                            </div>
                            <div style={{ background: masterBGColor, padding: '0px 10px', width: '100%', textAlign: 'center', position: 'fixed', bottom: 0 }}>
                                <div style={{ maxWidth: 420, margin: 'auto' }}>
                                    {btnPrev} {btnNext} {btnSubmit}
                                </div>
                            </div>
                        </>
                    )
                }
            }
        </MainContext.Consumer>
    )
}
