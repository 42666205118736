import React, { useState, useEffect } from 'react'
import { FormGroup, Label, Col, Input } from 'reactstrap'
import { SurveyData } from '../../shared/FHBData'
export type HaveParnerProps = {
    onUpdate: (data: SurveyData) => any,
    surveyData: SurveyData
}
export function HaveParner(props: HaveParnerProps) {
    const [formData, setFormData] = useState(props.surveyData)
    const [havePartner, setHavePartner] = useState(false)

    useEffect(
        () => {
            props.onUpdate(formData)
        }, [formData, props]
    )
    return (
        <>
            <FormGroup className="row">
                <Label md={12} ><h5>Marital Status</h5></Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="currentEmployer1" name="currentEmployer" type="radio" onClick={() => {
                                setHavePartner(true)
                                let frmData: SurveyData = formData
                                frmData.maritalStatus = 'Married/DeFacto'
                            }} />
                            Married/DeFacto <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="currentEmployer2" name="currentEmployer" type="radio" onClick={() => {
                                setHavePartner(false)
                                let frmData: SurveyData = formData
                                frmData.maritalStatus = 'Single'
                            }} />
                            Single <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>

            <FormGroup className="row" style={{ display: havePartner ? "" : 'none' }}>
                <Label htmlFor="postCode" sm="4"> Partner Name </Label>
                <Col sm="8">
                    <input
                        className="form-control"
                        id="postCode"
                        type="text"
                        required
                        onChange={
                            (e) => {
                                let frmData: SurveyData = formData
                                frmData.parnerEmploymentDetail!.name = e.target.value
                            }
                        }
                    />
                </Col>
            </FormGroup>
            <FormGroup className="row" style={{ display: havePartner ? "" : 'none' }}>
                <Label md={12} >Your Partner employment type * </Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType1" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.employmentType = 'Full time'
                                }
                            } />
                            Full time <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType2" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.employmentType = "Part time"
                                }
                            } />
                            Part time <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType3" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.employmentType = 'Casual'
                                }
                            } />
                            Casual <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="employmentType4" name="employmentType" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.employmentType = 'Self employed'
                                }
                            } />
                            Self employed <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row" style={{ display: havePartner ? "" : 'none' }}>
                <Label md={12} >Length of Employment * </Label>
                <Col md={12}>
                    <Label>
                        <Input placeholder="Year" type="number" onChange={(e) => {
                            let val = e.target.value
                            let frmData = formData
                            formData.parnerEmploymentDetail!.lengthOfEmployment.year = val as any
                            setFormData(frmData)
                        }} />
                    </Label>
                    <Label>
                        <Input placeholder="Month" type="number" onChange={(e) => {
                            let val = e.target.value
                            let frmData = formData
                            formData.parnerEmploymentDetail!.lengthOfEmployment.month = val as any
                            setFormData(frmData)
                        }} />
                    </Label>

                </Col>
            </FormGroup>
            <FormGroup className="row" style={{ display: havePartner ? "" : 'none' }}>
                <Label md={12} >Your Partner income category * </Label>
                <Col md={12}>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory0" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.incomeCategory = "$0-24k"
                                }
                            } />
                            $0-24k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory1" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.incomeCategory = "$25-49k"
                                }
                            } />
                            $25-49k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory2" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.incomeCategory = '$50-79k'
                                }
                            } />
                            $50-79k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory3" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.incomeCategory = '$80-99k'
                                }
                            } />
                            $80-99k <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" inline>
                        <Label check>
                            <Input defaultValue="option1" id="incomeCategory4" name="incomeCategory" type="radio" onClick={
                                () => {
                                    let frmData: SurveyData = formData
                                    frmData.parnerEmploymentDetail!.incomeCategory = "$100k+"
                                }
                            } />
                            $100k+ <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
        </>
    )
}