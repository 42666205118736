import React, { useState } from 'react';
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";
import "./assets/demo/demo.css";
import "./assets/demo/react-demo.css";
import "./assets/demo/nucleo-icons-page-styles.css";
import { HomePage2 } from './pages/HomePage2';
import * as firebase from 'firebase';

export type mainContextType = {
  firebaseApp: firebase.app.App;
}
export const MainContext = React.createContext<mainContextType>({
  firebaseApp: null as any
})


type AppProps = {
  firebaseApp: firebase.app.App;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  const [mainContext] = useState({
    firebaseApp: props.firebaseApp
  })

  return (
    <MainContext.Provider value={mainContext}>
      <HomePage2 firebaseApp={props.firebaseApp} />
    </MainContext.Provider>
  )
}

export default App;
